/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-large.less 2013-1-16 *******/
body {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.66666667;
}
.desk {
  width: 74.67811159%;
  max-width: 870px;
}
#home {
  float: right;
  width: 279px;
  height: 80px;
  margin-top: 110px;
  margin-right: 25.67164179%;
}
#home img {
  margin-left: -70px;
  transform: rotate(-90deg);
  transform-origin: right top;
}
#slogan {
  float: right;
  width: 230px;
  height: 14px;
  margin-top: 65px;
  margin-right: 20%;
}
#slogan img {
  margin-left: -14px;
  transform: rotate(-90deg);
  transform-origin: right top;
}
/*.cb-layout2 {
  #home {
    width: 184px;
    height: 52px;
    margin-top: 135px;
    margin-right: (100%/335*107);
    & img {
      margin-left: -52px;
    }
  }
  #slogan {
    width: 251px;
    height: 11px;
    margin-top: 51px;
    margin-right: (100%/335*92);
    & img {
      margin-left: 11px;
    }
  }
}*/
#head {
  margin-top: 48px;
}
.cb-layout3 #head {
  margin-top: 110px;
}
.cb-layout2 #head {
  margin-top: 122px;
}
#wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
}
.section.section--side {
  width: 22.33333333%;
  height: 100vh;
  z-index: 220;
}
.section.section--main {
  width: 77.66666667%;
  margin-left: 22.33333333%;
  margin-top: 0;
}
.container.container--mood {
  display: block;
}
.container.container--north {
  display: block;
  position: absolute;
  z-index: 10;
  bottom: 0;
  background-color: rgba(0, 44, 88, 0.9);
  color: #fff;
}
.container.container--footer {
  width: 100%;
  padding: 23px 0;
}
.container.container--footer #footerNav {
  float: left;
}
.container.container--footer #footerNav .meta {
  display: inline-block;
  margin: 10px 30px 10px 0;
}
.container.container--footer #social {
  float: right;
  margin-top: 12px;
}
.container.container--footer #social .meta {
  margin: 0;
}
.container.container--footer #services {
  margin-top: 25px;
}
.area.main,
.area.south,
.area.areaTen {
  width: 100%;
}
.area.main > .unit,
.area.south > .unit,
.area.areaTen > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.main h2,
.area.south h2,
.area.areaTen h2,
.area.main .foot,
.area.south .foot,
.area.areaTen .foot {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.area.main .part,
.area.south .part,
.area.areaTen .part,
.area.main > .grid table,
.area.south > .grid table,
.area.areaTen > .grid table {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
  width: 96.66666667%;
}
.area.main .tiny,
.area.south .tiny,
.area.areaTen .tiny {
  width: 46.66666667%;
}
.area.main > .slim,
.area.south > .slim,
.area.areaTen > .slim {
  width: 50%;
}
.area.main > .slim h2,
.area.south > .slim h2,
.area.areaTen > .slim h2,
.area.main > .slim .foot,
.area.south > .slim .foot,
.area.areaTen > .slim .foot,
.area.main > .slim .part,
.area.south > .slim .part,
.area.areaTen > .slim .part,
.area.main > .slim.grid table,
.area.south > .slim.grid table,
.area.areaTen > .slim.grid table {
  margin-right: 3.33333333%;
  margin-left: 3.33333333%;
}
.area.main > .slim .part,
.area.south > .slim .part,
.area.areaTen > .slim .part,
.area.main > .slim.grid table,
.area.south > .slim.grid table,
.area.areaTen > .slim.grid table {
  width: 93.33333333%;
}
.area.main > .slim .tiny,
.area.south > .slim .tiny,
.area.areaTen > .slim .tiny {
  width: 43.33333333%;
}
.area.north {
  width: 100%;
}
.area.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.north h2,
.area.north .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area.north .part,
.area.north > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.north > .slim .part,
.area.north > .slim.grid table {
  width: 100%;
}
.area.main,
.area.south,
.area.areaTen {
  width: 103.44827586%;
  margin-left: -1.72413793%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.area .unit {
  margin-top: 25px;
  margin-bottom: 25px;
}
.area .unit:not(.fold) + .unit.fold {
  margin-top: -10px;
}
.area .unit .part {
  margin-top: 8px;
  margin-bottom: 8px;
}
.area .unit .part.table {
  margin-top: 0;
  margin-bottom: 0;
}
.area .unit.edge {
  margin-top: 15px;
  margin-bottom: 15px;
}
.area .unit.edge .part {
  margin-top: 15px;
  margin-bottom: 15px;
}
.area.north {
  width: 88.50574713%;
}
.area.north .unit {
  margin-top: 20px;
  margin-bottom: 20px;
}
.area.main {
  margin-bottom: 25px;
}
.area.main:empty {
  margin-bottom: 0;
}
.area.main .unit.wide:first-child {
  margin-top: 5px;
}
.area.south {
  margin-top: 10px;
  margin-bottom: 25px;
}
.area.south:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.area.areaTen {
  margin-bottom: 25px;
}
.area.areaTen:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.area .unit.fold {
  width: 96.66666667%;
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.area .unit.fold .body .less .part {
  padding: 21px 3.33333333%;
}
.area .unit.fold .body .more .part:last-child {
  margin-bottom: 23px;
}
.area .unit.fold .part {
  width: 93.33333333%;
  padding-right: 3.33333333%;
  padding-left: 3.33333333%;
}
.area .unit.fold .part.tiny {
  width: 43.33333333%;
}
.area .unit.fold .foot .ctrl .less,
.area .unit.fold .foot .ctrl .more {
  height: 30px;
  padding: 21px 0;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.area .unit.fold .foot .ctrl .less {
  background-size: 16px 10px;
}
.area .unit.fold .foot .ctrl .more {
  background-size: 16px 10px;
}
.area .unit.flat {
  position: relative;
  overflow: hidden;
}
.area .unit.flat.wide {
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
}
.area .unit.flat.wide .part {
  width: 96.66666667%;
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.area .unit.flat.slim {
  width: 33.33333333%;
  margin-right: 0%;
  margin-left: 0%;
}
.area .unit.flat.slim .part {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
}
.area .unit.flat .part {
  margin-top: 0;
  margin-bottom: 0;
}
.area .unit.flat .part.text {
  position: absolute;
  top: calc(100% - 15px - 15px - (100vw/1500*25));
  box-sizing: border-box;
  height: 100%;
  padding: 15px 20px;
  transition: all 500ms;
}
@media only screen and (min-width: 1500px) {
  .area .unit.flat .part.text {
    top: calc(100% - 55px);
  }
}
.area .unit.flat .part.text p {
  font-size: 1.2vw;
}
@media only screen and (min-width: 1500px) {
  .area .unit.flat .part.text p {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.area .unit.flat .part.text .text-section {
  transition: all 0.218s;
}
.area .unit.flat:hover .part.text,
.area .unit.flat:focus .part.text {
  top: 0;
}
.area .unit.flat:hover .part.text > .text-section:first-child,
.area .unit.flat:focus .part.text > .text-section:first-child {
  margin-top: 50px!important;
}
.area .unit.seam {
  margin-top: 55px;
  margin-bottom: 55px;
}
.area .unit.seam.wide {
  width: 66.66666667%;
  margin-right: 0%;
  margin-left: 0%;
}
.area .unit.seam.wide .part {
  width: 90%;
  margin-right: 2.5%;
  margin-left: 2.5%;
}
.area .unit.seam.slim {
  width: 33.33333333%;
  margin-right: 0%;
  margin-left: 0%;
}
.area .unit.seam.slim .part {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
}
.area .unit.seam.slim .part.text {
  position: absolute;
  top: calc(100% - 15px - 15px - 5px - 5px - (100vw/1500*25));
  margin-top: 0;
}
@media only screen and (min-width: 1500px) {
  .area .unit.seam.slim .part.text {
    top: calc(100% - 67px);
  }
}
.area .unit.seam.slim .part.text p {
  font-size: 1.2vw;
}
@media only screen and (min-width: 1500px) {
  .area .unit.seam.slim .part.text p {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.area .unit.seam.slim .part.text p:first-child {
  box-sizing: border-box;
  padding: 15px 20px;
  padding-top: 15px!important;
  transition: all 0.218s;
}
.area .unit.seam.slim .part.text p:nth-child(2) {
  padding-top: 1.1em;
}
.area .unit.edge.wide {
  width: 96.66666667%;
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.area .unit.edge.wide .part {
  width: 75.28735632%;
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.area .unit.edge.wide .part.tiny {
  width: 17.81609195%;
}
.area .unit.edge.slim {
  height: 138px;
  width: 30%;
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
}
.area .unit.edge.slim .part {
  width: 89.25925926%;
  margin-right: 5.37037037%;
  margin-left: 5.37037037%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.area .unit.edge.slim .part.pict:first-child {
  filter: grayscale(100%);
}
.area .unit.edge.slim .part.pict:nth-child(2) {
  display: flex;
}
.area .unit.edge.slim:hover .part:first-child,
.area .unit.edge.slim:focus .part:first-child {
  filter: none;
}
.area .unit.edge.slim:hover .part:nth-child(2),
.area .unit.edge.slim:focus .part:nth-child(2) {
  opacity: 0;
}
div.area div.unit.flat.wide {
  width: 50%;
}
div.area div.unit.flat.wide div.part {
  margin-left: 3.33333333%;
  margin-right: 3.33333333%;
  width: 93.33333333%;
}
.area .part.text li:before {
  top: 0.7em;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
.area.north {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}
.unit.flat {
  line-height: 1.38888889;
}
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
h1 {
  font-size: 48px;
  font-size: 4.8rem;
  line-height: 1.2;
}
.area h2 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.25;
}
.cb-layout3 .area h2 {
  font-size: 48px;
  font-size: 4.8rem;
  line-height: 1.2;
}
.area h3 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
}
.skew {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.63636364;
}
.pale {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
.container.container--footer {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.25;
}
.container.container--footer #services {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.76923077;
}
/*# sourceMappingURL=./screen-large.css.map */